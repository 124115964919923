import React, { PropsWithChildren, forwardRef } from 'react';

interface IDivProps {
	className?: string;
	[key: string]: any;
}

const Div = forwardRef<HTMLDivElement, PropsWithChildren<IDivProps>>(({ children, className, ...props }, ref) => (
	<div className={className} {...props} ref={ref}>
		{children}
	</div>
));

export default Div;
