import React, { MouseEvent, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './styleButtonRow.css';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	text?: string;
	borderTop?: boolean;
	borderBottom?: boolean;
	className?: string;
	disabled?: boolean;
	stopOther?: boolean;
	onClick?: (e: MouseEvent) => void;
}

const ButtonRow = (props: ButtonProps) => {
	const {
		text,
		className,
		disabled = false,
		stopOther = true,
		borderTop = false,
		onClick = () => {},
		borderBottom = true,
		...other
	} = props;

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (stopOther) e.stopPropagation();
		if (!disabled && onClick) onClick(e);
	};

	return (
		<button
			type="button"
			disabled={disabled}
			onClick={handleClick}
			className={classNames(
				'button-row',
				disabled && 'disabled',
				borderTop && 'border-t',
				borderBottom && 'border-b',
				className,
			)}
			{...other}
		>
			<span>{text}</span>
			<ChevronRightIcon className="w-5" />
		</button>
	);
};

export default ButtonRow;
