import React, { useState, useRef, ChangeEvent, useEffect, KeyboardEventHandler, ClipboardEventHandler } from 'react';

import './style.css';
import classNames from 'classnames';

interface PinCodeInputProps {
	length: number;
	className: string;
	disabled: boolean;
	onChange: (value: string) => unknown;
}

const PinCodeInput: React.FC<PinCodeInputProps> = ({ length = 4, className, onChange, disabled = false }) => {
	const [pins, setPins] = useState<string[]>(Array(length).fill(''));
	const pinInputs = useRef<HTMLInputElement[]>([]);

	const handlePinChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		setPins((prevPins) => {
			const newPins = [...prevPins];
			newPins[index] = value;
			return newPins;
		});

		if (value !== '' && index < pinInputs.current.length - 1) {
			pinInputs.current[index + 1].focus();
		}
	};

	useEffect(() => {
		pinInputs.current[0]?.focus();
	}, []);

	useEffect(() => {
		onChange?.(pins.join(''));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pins]);

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
		const index = pinInputs.current.findIndex((input) => input === event.target);

		if (event.key === 'Backspace' && index > 0) {
			event.preventDefault();
			pinInputs.current[index - 1].focus();

			setPins((prevPins) => {
				const newPins = [...prevPins];
				newPins[index] = '';
				return newPins;
			});
		}

		if (!/^Backspace|Tab|\d{1}/.test(event.key) && !event.metaKey) event.preventDefault();
	};

	const handlePaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
		event.preventDefault();

		const clipboardData = event.clipboardData;
		const pastedData = clipboardData.getData('text/plain');
		const sanitizedData = pastedData.replace(/\D/g, '');
		const values = sanitizedData.split('').slice(0, length);

		values.forEach((val: string, index: number) => {
			if (pinInputs.current[index]) {
				pinInputs.current[index].value = val;
				handlePinChange(index, { target: { value: val } } as ChangeEvent<HTMLInputElement>);
			}
		});
	};

	return (
		<div className={classNames('pin-input', className)}>
			{pins.map((pin, index) => (
				<input
					style={{ width: 100 / length + '%' }}
					key={index}
					onKeyDown={handleKeyDown}
					pattern="[0-9]"
					inputMode="numeric"
					maxLength={1}
					value={pin}
					onPaste={handlePaste}
					onChange={(event) => handlePinChange(index, event)}
					ref={(input) => (pinInputs.current[index] = input as HTMLInputElement)}
					required
					disabled={disabled}
				/>
			))}
		</div>
	);
};

export default PinCodeInput;
