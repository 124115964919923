const GooglePlayIconSolid = ({ className = 'w-[20px] h-[20px]' }) => (
	<svg
		className={className}
		height="56.6934px"
		version="1.1"
		viewBox="0 0 56.6934 56.6934"
		width="56.6934px"
		fill="currentColor"
	>
		<path d="M29.2066,4.3736c-13.5996,0-24.625,11.0234-24.625,24.623c0,13.5997,11.0254,24.625,24.625,24.625  c13.5986,0,24.624-11.0253,24.624-24.625C53.8307,15.397,42.8052,4.3736,29.2066,4.3736z M22.0914,14.6657l15.5147,8.3062  l-4.7339,4.7339L19.6543,14.4879C20.4108,14.212,21.2851,14.2341,22.0914,14.6657z M17.6713,40.1202V17.3129  c0-0.7969,0.2988-1.4925,0.7728-2.0135l13.4172,13.4171L18.4441,42.1337C17.9701,41.6127,17.6713,40.9172,17.6713,40.1202z   M22.0914,42.7675c-0.8063,0.4316-1.6805,0.4536-2.4371,0.1778l13.2178-13.2179l4.7339,4.7339L22.0914,42.7675z M43.3918,31.3638  l-4.469,2.3926l-5.0399-5.0399l5.0399-5.0398l4.469,2.3926C45.5058,27.2011,45.5058,30.2321,43.3918,31.3638z" />
	</svg>
);

export default GooglePlayIconSolid;
