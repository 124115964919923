import React, { PropsWithChildren, forwardRef } from 'react';

interface ISpanProps {
	className?: string;
	[key: string]: any;
}

const Span = forwardRef<HTMLDivElement, PropsWithChildren<ISpanProps>>(({ children, className, ...props }, ref) => (
	<span className={className} {...props} ref={ref}>
		{children}
	</span>
));

export default Span;
