import React, { forwardRef, Fragment, ForwardRefRenderFunction } from 'react';

import './style.css';

interface Props {
	data?: Array<any>;
	renderItem: (item: any) => JSX.Element | null;
	className?: string;
}

type ItemListNewType = ForwardRefRenderFunction<HTMLDivElement, Props>;

const ItemListNew: ItemListNewType = ({ data, renderItem, className = 'item-list' }, ref) => {
	if (!data || !Array.isArray(data)) return null;

	const keyExtractor = (item: any) => (item && item?.id ? item.id.toString() : null);

	return (
		<div className={className} ref={ref}>
			{data.map((item) => (
				<Fragment key={keyExtractor(item)}>{renderItem(item)}</Fragment>
			))}
		</div>
	);
};

export default forwardRef(ItemListNew);
