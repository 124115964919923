import classNames from 'classnames';
import React, { ForwardRefExoticComponent, FunctionComponent, MouseEvent, PropsWithChildren } from 'react';

import './style.css';

interface ICardProps {
	className?: string;
	onClick?: (e: MouseEvent<HTMLDivElement>) => void;
	[key: string]: any;
}

type ICard = ForwardRefExoticComponent<PropsWithChildren<ICardProps>> & {
	Header?: FunctionComponent<PropsWithChildren<ICardProps>>;
	Footer?: FunctionComponent<PropsWithChildren<ICardProps>>;
	Body?: FunctionComponent<PropsWithChildren<ICardProps>>;
};

const Card: ICard = React.forwardRef<HTMLDivElement, PropsWithChildren<ICardProps>>((props, ref) => {
	const { children, className, onClick = () => {}, ...stuff } = props;
	return (
		<div ref={ref} className={classNames('card', className)} onClick={onClick} {...stuff}>
			{children}
		</div>
	);
});

const Header: FunctionComponent<PropsWithChildren<ICardProps>> = ({
	children,
	className,
	onClick = () => {},
	...props
}) => {
	return (
		<div className={classNames('card-header', className)} onClick={onClick} {...props}>
			{children}
		</div>
	);
};

const Body: FunctionComponent<PropsWithChildren<ICardProps>> = ({
	children,
	className,
	onClick = () => {},
	...props
}) => {
	return (
		<div className={classNames('card-body', className)} onClick={onClick} {...props}>
			{children}
		</div>
	);
};

const Footer: FunctionComponent<PropsWithChildren<ICardProps>> = ({
	children,
	className,
	onClick = () => {},
	...props
}) => {
	return (
		<div className={classNames('card-footer', className)} onClick={onClick} {...props}>
			{children}
		</div>
	);
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
