import classNames from 'classnames';
import React, { useState, useEffect, RefAttributes } from 'react';
import useAutoOptionsPosition from 'vgui/hooks/useAutoOptionsPosition.tsx';

import { Button } from '../button';
import { Spinner } from '../elements';
import './style.css';

type Fn = () => void;

interface IDropdown {
	maxHeight?: number | string;
	render?: (args: {
		selected?: boolean;
		item?: any;
		onClick?: Fn;
		key?: string | number;
		disabled?: boolean;
		elementId?: string;
	}) => JSX.Element;
	loading?: boolean;
	className?: string;
	optionPlaceholder?: string;
	isComponentVisible?: boolean;
	setIsComponentVisible?: Fn;
	options?: Array<{ id: number | string; value: string; disabled?: boolean; onClick?: Fn; selected?: boolean }>;
	onChange?: (data: { id: number | string; value: string }) => void;
	id?: string | string;
}

type DropdownComponent = IDropdown &
	RefAttributes<HTMLDivElement> & {
		Option?: (props: any) => JSX.Element;
	};

const Dropdown: DropdownComponent = React.forwardRef<HTMLDivElement, IDropdown>(
	(props, ref: React.MutableRefObject<any>) => {
		const {
			maxHeight,
			render,
			loading,
			className,
			optionPlaceholder,
			isComponentVisible,
			setIsComponentVisible,
			options = [],
			onChange,
			id,
		} = props;
		const { optionsRef } = useAutoOptionsPosition(isComponentVisible, ref, options);
		const [selectedId, setSelectedId] = useState(id || '');

		useEffect(() => {
			if (isComponentVisible && options?.length > 0) {
				const item = options.filter((el) => el?.id === selectedId)[0];
				const option = document.getElementById(`dropdown-option-${selectedId}-${item?.value}`);
				const offsetTop = option?.offsetTop || 0;
				optionsRef.current.scrollTop = offsetTop - Math.round(optionsRef.current.clientHeight / 2) + 25;
			}
		}, [selectedId, isComponentVisible, options, optionsRef]);

		return (
			<div
				ref={optionsRef}
				id="options-list"
				style={{ maxHeight }}
				className={classNames('options', { hidden: !isComponentVisible }, className)}
			>
				{loading ? (
					<div className="px-3 py-2">
						<Spinner size="xs" />
					</div>
				) : options.length ? (
					options.map((item, key) => {
						if (!item) return '';

						const { value, disabled = false, onClick: defClick } = item;
						const id = 'id' in item ? item.id : key;
						const onClick = () => {
							if (onChange) setSelectedId(id);
							if (defClick) defClick();
							if (onChange) onChange({ id, value });
							setIsComponentVisible(false);
						};
						const selected = selectedId === id || item?.selected === true;

						const elementId = `dropdown-option-${selectedId}-${value}`;

						return render ? (
							render({ selected, item, onClick, key: id, disabled, elementId })
						) : value === '-' ? (
							<hr key={id} />
						) : (
							<Option key={id} id={elementId} {...{ selected, onClick, disabled }}>
								{value}
							</Option>
						);
					})
				) : (
					<span className="text-sm px-3 py-1.5 block">{optionPlaceholder || 'Ничего не найдено'}</span>
				)}
			</div>
		);
	},
);

const Option = ({ id, children, onClick, selected, disabled }) => {
	return (
		<Button
			size="small"
			variant="transparent"
			{...{ id, onClick, disabled }}
			className={classNames('option', selected && 'selected')}
			onMouseDown={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
		>
			{typeof children === 'string' ? <span className="truncate">{children}</span> : children}
		</Button>
	);
};

Dropdown.Option = Option;

export default Dropdown;
