import blockImage from 'assets/images/blocked.svg';
import errorImage from 'assets/images/noimage.png';
import defaultImage from 'assets/images/nophoto.svg';
import classNames from 'classnames';
import * as SIZE from 'constants/Size';
import React, { FunctionComponent, MouseEvent } from 'react';
import { Image } from 'vgui';

import './style.css';

type AvatarSize = keyof typeof sizes;

interface IAvatarProps {
	url?: string;
	online?: boolean;
	deleted?: boolean;
	rounded?: boolean;
	size?: AvatarSize;
	alt?: string;
	title?: string;
	className?: string;
	onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const sizes = {
	xxs: 20,
	xs: 32,
	sm: 40,
	md: 75,
	lg: 100,
	xl: 150,
} as const;

const Avatar: FunctionComponent<IAvatarProps> = (props) => {
	const { url, online, deleted, rounded, size = 'sm', alt, title, className, onClick = () => {} } = props;

	// if (!url) return null;

	const currentSize = sizes[size];

	return (
		<div className={classNames(className, `avatar avatar-${size}`, rounded && 'avatar-rounded')}>
			<Image
				alt={alt}
				title={title}
				src={url ? (deleted ? blockImage : SIZE.crop(url, currentSize + 'x' + currentSize)) : defaultImage}
				width={currentSize}
				height={currentSize}
				onClick={onClick}
				onError={(e) => (e.currentTarget.src = errorImage)}
			/>
			{online && <i />}
		</div>
	);
};

export default Avatar;
