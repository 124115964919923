const OKIconSolid = ({ className }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 48 48"
		width="20px"
		height="20px"
		fill="currentColor"
	>
		<path d="M 24 2 C 17.925 2 13 6.925 13 13 C 13 19.075 17.925 24 24 24 C 30.075 24 35 19.075 35 13 C 35 6.925 30.075 2 24 2 z M 24 8.9472656 C 26.238 8.9472656 28.052734 10.761 28.052734 13 C 28.052734 15.238 26.238 17.052734 24 17.052734 C 21.762 17.052734 19.947266 15.239 19.947266 13 C 19.947266 10.762 21.761 8.9472656 24 8.9472656 z M 33.185547 24.046875 C 32.402922 24.009469 31.6245 24.203187 30.9375 24.617188 C 26.7875 27.116187 21.2115 27.116187 17.0625 24.617188 C 15.1725 23.478188 12.710266 24.090469 11.572266 25.980469 C 11.021266 26.896469 10.858234 27.970813 11.115234 29.007812 C 11.373234 30.044812 12.019547 30.920703 12.935547 31.470703 C 14.396547 32.350703 15.959516 33.049594 17.603516 33.558594 L 12.009766 39.339844 C 11.300766 40.138844 10.942859 41.165422 11.005859 42.232422 C 11.068859 43.299422 11.542797 44.276328 12.341797 44.986328 C 13.105797 45.665328 14.060812 45.998047 15.007812 45.998047 C 16.103813 45.998047 17.189938 45.552547 17.960938 44.685547 L 24 38.443359 L 30.013672 44.652344 C 30.771672 45.506344 31.861906 45.994141 33.003906 45.994141 C 33.982906 45.994141 34.926156 45.635375 35.660156 44.984375 C 37.308156 43.519375 37.458844 40.986641 35.964844 39.306641 L 30.396484 33.558594 C 32.040484 33.048594 33.605406 32.34875 35.066406 31.46875 C 35.981406 30.91775 36.627766 30.044813 36.884766 29.007812 C 37.141766 27.970813 36.978734 26.895469 36.427734 25.980469 C 35.876734 25.065469 35.003797 24.419109 33.966797 24.162109 C 33.707797 24.097609 33.446422 24.059344 33.185547 24.046875 z" />
	</svg>
);

export default OKIconSolid;
