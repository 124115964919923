const RuTubeIconSolid = ({ className }) => (
	<svg className={className} fill="currentColor" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
		<path
			d="M323.4,138.8H96.1v252.7h63.3v-82.2h121.2l55.3,82.2h70.9l-61-82.6c18.9-3,32.6-10.2,40.9-21.6s12.5-29.6,12.5-53.8v-18.9
	c0-14.4-1.5-25.8-4.2-34.5c-2.7-8.7-7.2-16.3-13.6-23.1c-6.8-6.4-14.4-11-23.5-14C348.8,140.3,337.4,138.8,323.4,138.8z
	 M313.2,253.6H159.3v-59.1h153.8c8.7,0,14.8,1.5,17.8,4.2c3,2.7,4.9,7.6,4.9,14.8v21.2c0,7.6-1.9,12.5-4.9,15.2
	C327.9,252.4,321.9,253.6,313.2,253.6z"
		/>
		<circle cx="419.5" cy="110" r="32.3" />
		<path
			fill="none"
			strokeWidth="14"
			stroke="currentColor"
			d="M108.6,7h294.8C459.5,7,505,52.5,505,108.6v294.8c0,56.1-45.5,101.7-101.7,101.7H108.6
	C52.5,505,7,459.5,7,403.4V108.6C7,52.5,52.5,7,108.6,7z"
		/>
	</svg>
);

export default RuTubeIconSolid;
